import AuthHelper from '@/helpers/AuthHelper';

export default {
  SET_USER(state, user) {
    state.authUser = user;
  },
  SET_LOCATION(state, coords) {
    state.userLocation = coords;
  },
  SET_COORDS(state, { coords, zoom }) {
    state.currentCoords = {
      coords,
      zoom
    };
  },
  SET_BUSINESS_REGIONS(state, regions) {
    state.businessRegions = regions;
  },
  SET_GEO_REGIONS(state, regions) {
    state.geoRegions = regions;
  },
  SET_DIRECTIONS(state, directions) {
    state.directions = directions;
  },
  SET_FACILITY_TYPES(state, types) {
      state.objectTypes = types;
    },
  SET_OBJECTS(state, objects) {
    state.objects = objects;
  },
  SET_PARTNER_TYPES(state, types) {
    state.partnerTypes = types;
  },
  SET_PARTNER_ATTRIBUTES(state, types) {
    state.partnerAttributes = types;
  },
  SET_PARTNERS(state, partners) {
    state.partners = partners;
  },
  SET_GROUPS(state, groups) {
    state.productGroups = groups;
  },
  SET_PRODUCTS(state, products) {
    state.products = products;
  },
  SET_SOCIALS(state, socials) {
    state.socials = socials;
  },
  SET_ACTIVE_DIRECTIONS(state, directions) {
    state.activeDirections = directions;
  },
  SET_ACTIVE_LAYER(state, layer) {
    state.activeLayer = layer;
  },
  SET_ACTIVE_ITEM(state, id) {
    state.activeItemId = null;
    state.activeItemId = id;
  },
  SET_PARTNER_FILTER(state, list) {
    state.partnerFilter = list
  },
  SET_OBJECT_RANGE_FILTER(state, range) {
    state.objectRangeFilter = range
  },
  SET_OBJECT_FILTER(state, list) {
    state.objectsFilter = list
  },
  SET_AVAILABLE(state, list) {
    state.availables = list;
  },
  SET_VISIBLE(state, list) {
    state.visibles = list;
  },
  SET_SEARCH_RESULTS(state, results) {
    state.searchResults = results;
  },
  ADD_VISITED_OBJECTS(state, id) {
    state.objects.find(obj => obj.id === id).visited = true;
  },
  ADD_VISITED_PARTNERS(state, id) {
    state.partners.find(partner => partner.id === id).visited = true;
  },
  ADD_DOT(state) {
    state.dot = true;
  },
  REMOVE_DOT(state) {
    state.dot = false;
  },
  TOGGLE_LOCATION_CHOICE(state) {
    state.isChoosingLocation = !state.isChoosingLocation;
  },
  SET_LOCATION_NAME(state, name) {
    state.locationName = name;
  },
  SET_PHONE_MASKS(state, list) {
    state.phoneMasks = list;
  }
}
