<template>
  <div v-if="isVisible()" :key="'over-' + item.id">
    <ymap-marker :key="item.id + '-marker-' + interactionKey" :properties="markerLayout" :coords="item.coords"
      :marker-id="item.id" :icon="icon" :options="{ iconColor: iconColor() }" :cluster-name="item.layer"
      :hint-content="hintContent()" @click="openCard" @mouseenter="hovered = true" @mouseleave="hovered = false" />
  </div>
</template>

<script>
import { ymapMarker } from 'vue-yandex-maps'
import colors from '@/helpers/DirectionColors'

export default {
  name: 'MapMarker',
  props: {
    item: Object
  },
  components: {
    ymapMarker
  },
  data() {
    return {
      interactionKey: 0,
      hovered: false,
      icon: {
        layout: 'default#imageWithContent',
        imageHref: '/',
        imageSize: [70, 70]
      }
    }
  },
  watch: {
    markerLayout() {
      this.interactionKey += 1;
    }
  },
  methods: {
    openCard() {
      this.hovered = false;
      this.$store.dispatch('addVisited', this.item.id);
      this.$store.dispatch('setActiveItem', this.item.id);
      this.$router.push(`/${this.activeLayer}/${this.item.id}`);
    },
    iconColor() {
      const borderColors = this.item.directions.map(direction => colors[direction]);
      return borderColors[Math.round(Math.random() * (borderColors.length - 1))];
    },
    hintContent() {
      if (this.item.layer === 'partners' && !this.item.is_sertified) {
        return `<div class="map-marker__content map-marker__content--hint ${this.item.visited ? 'map-marker__content--visited' : ''}">
                    <p class="map-marker__name">${this.item.name}</p>
                    <p class="map-marker__type">${this.item.typeName}</p>
                    </div>
                 </div>`;
      }
      return '';
    },
    isActive() {
      const activeItem = this.$store.getters['getActiveItem'];
      return this.item.id === activeItem;
    },
    iconImage() {
      if (this.item.layer === 'objects' || this.item.directions.length > 1) {
        return '/img/logo.svg';
      }
      const partnerTypes = this.$store.getters['getPartnerTypes'];
      const item = partnerTypes.find(type => type.id === this.item.type);
      return item.image ? item.image.thumbnail_link : item.link;
    },
    isVisible() {
      // const visibles = this.$store.getters['getVisibles'];
      return true;
    }
  },
  computed: {
    markerLayout() {
      const borderColors = [...this.item.directions].map(direction => colors[direction]);
      const icon = `<svg data-src="${this.iconImage()}" fill="${(this.hovered || this.isActive()) ? '#fff' : borderColors[0]}" class="map-marker__logo"></svg>`;
      let border = '';
      let tip = '';
      let point = `<div class="map-marker__point ${this.item.visited ? 'map-marker__point--visited' : ''}" style="background-color: ${borderColors.length === 1 ? borderColors[0] : '#4E4E4E'}"></div>`;
      const borderWidth = window.innerWidth > 767 ? 7 : 5;

      switch (borderColors.length) {
        case 2:
          border = `<div class="map-marker__border ${this.hovered ? 'hovered' : ''}">
              <svg viewBox="0 0 70 70">
                <circle cx="35" cy="35" r="35" fill="${borderColors[0]}"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0V70Z" fill="${borderColors[1]}"/>
                <circle cx="35" cy="35" r="28" fill="white"/>
              </svg>
            </div>`;
          break;
        case 3:
          border = `<div class="map-marker__border ${this.hovered ? 'hovered' : ''}">
              <svg viewBox="0 0 70 70">
                <circle cx="35" cy="35" r="35" fill="${borderColors[0]}"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0V70Z" fill="${borderColors[1]}"/>
                <path d="M35 0C19.5128 0 6.375 10.059 1.76352 24L35 35L68.2365 24C63.625 10.059 50.4872 0 35 0Z" fill="${borderColors[2]}"/>
                <circle cx="35" cy="35" r="28" fill="white"/>
              </svg>
            </div>`;
          break;
        case 4:
          border = `<div class="map-marker__border ${this.hovered ? 'hovered' : ''}">
              <svg viewBox="0 0 70 70">
                <circle cx="35" cy="35" r="35" fill="${borderColors[0]}"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35L70 35Z" fill="${borderColors[1]}"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0V70Z" fill="${borderColors[2]}"/>
                <g clip-path="url(#clip)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35L70 35Z" fill="${borderColors[3]}"/>
                </g>
                <circle cx="35" cy="35" r="28" fill="white"/>
                <defs>
                <clipPath id="clip">
                <rect width="35" height="35" fill="white" transform="translate(35)"/>
                </clipPath>
                </defs>
              </svg>
            </div>`;
          break;
        default:
          border = `<div class="map-marker__border ${this.item.layer === 'objects' && this.hovered ? 'hovered' : ''}"
                    style="background-color: ${this.item.layer === 'partners' && (this.hovered || this.isActive()) ? borderColors[0] : '#fff'};
                    border: ${borderWidth}px solid ${borderColors[0]}"></div>`;
      }
      if (borderColors.length === 4 && this.isActive()) {
        tip = `<div class="map-marker__tip map-marker__tip--double ${this.item.visited ? 'map-marker__tip--visited' : ''}" style="background-color: ${borderColors[0]}; color: ${borderColors[2]}"></div>`;
      } else if (borderColors.length > 1 && this.isActive()) {
        tip = `<div class="map-marker__tip map-marker__tip--double ${this.item.visited ? 'map-marker__tip--visited' : ''}" style="background-color: ${borderColors[0]}; color: ${borderColors[1]}"></div>`;
      } else if (this.isActive()) {
        tip = `<div class="map-marker__tip ${this.item.visited ? 'map-marker__tip--visited' : ''}" style="background-color: ${borderColors[0]}"></div>`
      } else {
        point = '';
      }

      let tag = `<div class="map-marker__content ${this.item.visited ? 'map-marker__content--visited' : ''}">
                    <p class="map-marker__name">${this.item.name}</p>
                    <p class="map-marker__type">${this.item.typeName}</p>
                    </div>
                 </div>`;
      if (this.item.layer === 'partners' && !this.item.is_sertified) {
        tag = '';
      }
      return {
        iconContent:
          `<div class="map-marker${this.isVisible() ? '' : ' map-marker--invisible'}">
          <div class="map-marker__circle ${this.item.visited ? 'map-marker__circle--visited' : ''}${this.item.layer === 'partners' ? ' map-marker__circle--partner' : ''}">${border}${icon}</div>
          ${tip}${point}
          ${tag}`
      };
    },
    activeLayer() {
      return this.$store.getters['getActiveLayer'];
    }
  }
}
</script>

<style scoped>
</style>
